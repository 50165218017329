<template>
  <div>
    <DataGridFactory
      :dataSource="store.DocIcms.table"
      :enabledActions="false"
      :nameToExport="'Totais ICMS'"
    >
      <template v-slot:column>
        <DxColumn data-field="modelo" alignment="right" />
        <DxColumn data-field="serie" alignment="right" caption="Série" />
        <DxColumn data-field="numero" alignment="right" caption="Número"  />
        <DxColumn
          data-field="tipoDocumento"
          alignment="center"
          caption="Tipo"
          :calculate-cell-value="(rowData) => {return Validators.formataTipo(rowData.tipoDocumento)}"
        />
        <DxColumn
          data-field="finalidade"
          alignment="center"
          :calculate-cell-value="(rowData) => {return Validators.formataFinalidade(rowData.finalidade)}"
        />
        <DxColumn
          data-field="valor"
          data-type="number"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="valorBc"
          data-type="number"
          caption="Base Calc."
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="valorIcms"
          data-type="number"
          caption="ICMS"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="valorICMSDeson"
          data-type="number"
          caption="Icms Deson."
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="valorFcp"
          data-type="number"
          caption="FCP"
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="valorFcpUfDest"
          data-type="number"
          caption="FCP UF Dest."
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="valorIcmsUfDest"
          data-type="number"
          caption="ICMS UF Dest."
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
        <DxColumn
          data-field="valorIcmsUfRemet"
          data-type="number"
          caption="ICMS UF Remet."
          :format="{ type: 'fixedPoint', precision: 2 }"
        />
      </template>

      <template v-slot:footer>
        <DxExport :enabled="true" />

        <DxSummary>
          <DxTotalItem
            column="modelo"
            alignment="left"
            summary-type="count"
            display-format="{0} Registros"
         />
          <DxTotalItem
            column="valor"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="valorBc"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="valorIcms"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="valorICMSDeson"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="valorFcp"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="valorFcpUfDest"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="valorIcmsUfDest"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
          <DxTotalItem
            column="valorIcmsUfRemet"
            alignment="right"
            summary-type="sum"
            display-format="R$: {0}"
            :value-format="{type: 'fixedPoint', precision: 2, currency: 'BRL'}"
          />
        </DxSummary>
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>