import { DxColumn, DxExport, DxSummary, DxTotalItem } from 'devextreme-vue/ui/data-grid';
import DataGridFactory from '../../../datagrid-factory/index.vue';
import { documentoStore } from '../../../../store/modules/documentos';
import { defineComponent } from 'vue';
import Validators from '../../../../helpers/validators-input';


export default defineComponent({
  name: 'Icms',
  setup() {
    const store = documentoStore()
    
    return {
      store,
      Validators
    }
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxExport,
    DxSummary,
    DxTotalItem
  }
})